exports.components = {
  "component---src-pages-card-index-js": () => import("./../../../src/pages/Card/index.js" /* webpackChunkName: "component---src-pages-card-index-js" */),
  "component---src-pages-listing-index-js": () => import("./../../../src/pages/Listing/index.js" /* webpackChunkName: "component---src-pages-listing-index-js" */),
  "component---src-pages-main-index-js": () => import("./../../../src/pages/Main/index.js" /* webpackChunkName: "component---src-pages-main-index-js" */),
  "component---src-pages-privacy-policy-index-js": () => import("./../../../src/pages/PrivacyPolicy/index.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-js" */),
  "component---src-pages-terms-of-use-index-js": () => import("./../../../src/pages/TermsOfUse/index.js" /* webpackChunkName: "component---src-pages-terms-of-use-index-js" */),
  "component---src-pages-widget-cards-index-js": () => import("./../../../src/pages/WidgetCards/index.js" /* webpackChunkName: "component---src-pages-widget-cards-index-js" */),
  "component---src-pages-widget-index-js": () => import("./../../../src/pages/Widget/index.js" /* webpackChunkName: "component---src-pages-widget-index-js" */)
}

