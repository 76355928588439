import AnalyticsProvider from "@mkt/analytics/components/AnalyticsProvider"

export const onRouteUpdate = () => {
  window.locations = window.locations || [document.referrer]
  locations.push(window.location.href)
  window.previousPath = locations[locations.length - 2]
}

export const onInitialClientRender = () => {
  // const loadScripts = (w, d, s, l, i) => {
  //   w[l] = w[l] || []
  //   w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" })
  //   var f = d.getElementsByTagName(s)[0],
  //     j = d.createElement(s),
  //     dl = l != "dataLayer" ? "&l=" + l : ""
  //   j.async = true
  //   j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl
  //   f.parentNode.insertBefore(j, f)
  // }

  const body = document.querySelector("body")
  const noscript = document.createElement("noscript")
  const iframe = document.createElement("iframe")

  iframe.setAttribute(
    "src",
    `https://www.googletagmanager.com/ns.html?id=GTM-5RJHW4C`
  )
  iframe.setAttribute("width", "0")
  iframe.setAttribute("height", "0")
  iframe.setAttribute("style", "display:none;visibility:hidden")

  noscript.append(iframe)

  body.append(noscript)

  // window.addEventListener(
  //   "load",
  //   loadScripts(window, document, "script", "dataLayer", "GTM-5RJHW4C"),
  //   false
  // )
}

export const wrapPageElement = ({ element, props }) => {
  const pageContent = props?.pageContext?.content || {}

  return (
    <AnalyticsProvider
      partnerName="iq Marketplace"
      experienceType="credit"
      pageContent={pageContent}
    >
      {element}
    </AnalyticsProvider>
  )
}
