import {
  startPixel,
  startGTM,
  startTtq,
  startAdobe,
} from "@mkt/analytics/redline"
import { trackCookies } from "./cookies"
import { APP_NAME } from "gatsby-env-variables"

/**
 *
 * @param {boolean} trackersLoaded
 * @param {*} setTrackersLoaded
 * @param {import("redline-client-sdk").Redline} rlInstance
 */
export const startTrackers = (
  trackersLoaded,
  setTrackersLoaded,
  rlInstance
) => {
  if (!trackersLoaded) {
    rlInstance
      .start()
      .then((_) => {
        if (APP_NAME === "mastercard") startAdobe()
      })
      .then((_) => {
        if (APP_NAME !== "mastercard") startPixel()
      })
      // .then((_) => {
      //   if (APP_NAME !== "mastercard") startGTM()
      // })
      .then((_) => {
        if (APP_NAME !== "mastercard") startTtq()
      })
      .then((_) => setTrackersLoaded(true))
      .then((_) => {
        if (APP_NAME !== "mastercard") trackCookies()
      })
  }
}
